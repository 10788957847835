<template>
<div class="display-block">
    <v-card>
        <v-card-text>
            <h3>Supplied Item Details :</h3>
            <v-layout wrap>
                <v-flex xs12 sm6>
                    <row-item label="Name : " :value="suppliedItem.name"></row-item>
                </v-flex>
                <v-flex xs12 sm6>
                    <row-item label="Order No : " :value="suppliedItem.order.number"></row-item>
                </v-flex>
                <v-flex xs12 sm6>
                    <row-item label="Price : " :value="suppliedItem.price.toString()"></row-item>
                </v-flex>
                <v-flex xs12 sm6>
                    <row-item label="Status : " :value="suppliedItem.status"></row-item>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-flex text-right>
            <supply-action v-model="suppliedItem"></supply-action>
        </v-flex>
    </v-card>
</div>
</template>

    
<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import SupplyAction from '@/components/SupplyAction'
export default {
    components: {
        RowItem,
        SupplyAction
    },
    data() {
        return {
            id: 0,
            suppliedItem: {
                price: 0,
                name: '',
                status: 'PENDING',
                order: {
                    number: ''
                }
            },
            items: [],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.suppliedItem = await this.getItem(appConstants.SUPPLIED_ITEM_API + "/" + this.id)
        },
    }
}
</script>
