<template>
<div>
    <v-layout>
        <v-flex sm11 text-right v-if="supplyItem && supplyItem.status=='PENDING'">
            <v-btn @click="updateSupplyItem(action)" v-for="action in allowedActions" :key="action" :style="theme" class="ma-1">{{action}}</v-btn>
        </v-flex>
        <v-flex :class="supplyItem.status=='PENDING'?'sm1':'sm12'" text-right>
            <v-btn @click="$router.go(-1)" class="ma-1" :style="theme">Back</v-btn>
        </v-flex>
    </v-layout>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            actions: [],
            selectedAction: "",
        }
    },
    computed: {
        supplyItem: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        },
        allowedActions() {
            if (this.supplyItem.status && this.actions.length == 0 && this.supplyItem._id) {
                this.getItem(appConstants.SUPPLY_ALLOWED_ACTIONS.replace('$id', this.supplyItem._id)).then(response => {
                    this.actions = response
                })
            }
            if (this.actions[0] != null)
                return this.actions
        }
    },
    methods: {
        async updateSupplyItem(action) {
            this.supplyItem.status = action
            await this.putItem("/api/suppliedItem/" + this.supplyItem._id, this.supplyItem)
        },
    },
}
</script>

<style scoped>
.text-field {
    width: 50px;
    padding-top: 20%;
}

h4 {
    color: red
}

.item-code {
    font-size: 11px !important;
    padding-bottom: 0%;
}

.card-text {
    font-size: 13px;
    padding: 0;
    padding-left: 20%;
}

.centered-input>>>input {
    padding-top: 15px;
    text-align: center;
    font-size: 1.2rem;
}

.item-value {
    font-size: 1.1rem;
    padding-bottom: 0;
    padding-left: 40%;
}
</style>
